// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ce4ac8cb-d122-4d36-8d53-676623243cc5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FcnxVRzUT",
    "aws_user_pools_web_client_id": "7cpt31e572kt9k4kvk8d65b30",
    "aws_content_delivery_bucket": "travel.cocoasys.net",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2nd0j0yk6la1s.cloudfront.net"
};


export default awsmobile;
