import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/App/Layout"
import Details from "../components/App/Details"
import Home from "../components/App/Home"
import Login from "../components/App/Login"
import PrivateRoute from "../components/App/PrivateRoute"
import Status from "../components/App/Status"
import Register from "../components/App/Register"


import Amplify from 'aws-amplify';
import aws_exports from '../aws-exports';
Amplify.configure(aws_exports);

const App = () => (
  <Layout>
    <Status />
    <Router>
      <PrivateRoute path="/app/profile" component={Home} />
      <PrivateRoute path="/app/details" component={Details} />
      <Login path="/app/login" />
      <Register path="/app/register" />
    </Router>
  </Layout>
)

export default App