///import { Auth } from 'aws-amplify';

  const isBrowser = typeof window !== `undefined`
  
  const getUser = () =>
    window.localStorage.gatsbyUser
      ? JSON.parse(window.localStorage.gatsbyUser)
      : {}
  
  const setUser = user => (window.localStorage.gatsbyUser = JSON.stringify(user))
   
  
  export const handleLogin = ({ username, password }) => {
  
  
  /*
  
    Auth.signUp({
    username : 'rgarcia',
    password: 'Abc12345!',
    attributes: {
      email : 'rgarcia@cocoasystems.net',          // optional
      phone_number : '+2028023130',   // optional - E.164 number convention
      // other custom attributes 
    },
    validationData: []  //optional
    })
    .then(data => { 
      console.log (data)

    })
    .catch(err => console.log(err));
    */
  
  /*
   Auth.confirmSignUp('rgarcia', '101026', {
    // Optional. Force user confirmation irrespective of existing alias. By default set to True.
    forceAliasCreation: true    
  }).then(data => console.log(data))
  .catch(err => console.log(err));
  */
  
    
  ///  Auth.signIn('rgarcia', 'Abc12345!')
  ///  .then(user => console.log(user))
  ///  .catch(err => console.log("cognito err" , err));
    
  
  
  
    if (!isBrowser) return false
  
    if (username === `gatsby` && password === `demo`) {
      console.log(`Credentials match! Setting the active user.`)
      return setUser({
        name: `Jim`,
        legalName: `James K. User`,
        email: `jim@example.org`,
      })
    }
  
    return false
  }
  
  export const isLoggedIn = () => {
    if (!isBrowser) return false
  
    const user = getUser()
  
    return !!user.email
  }
  
  export const getCurrentUser = () => isBrowser && getUser()
  
  export const logout = callback => {
    if (!isBrowser) return
  
    console.log(`Ensuring the \`gatsbyUser\` property exists.`)
    setUser({})
    callback()
  }
  